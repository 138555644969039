import React, { useEffect, useState } from 'react'
import "./MyWork.css"
import wt from "./../../assets/wt.jpg"
import netflix from "./../../assets/netflix.png";
import td from "./../../assets/td.jpg";
import akptc from "./../../assets/akptc.jpg";
import axios from "axios";
import respc from "./../../assets/respc.jpg"
const MyWork = () => {
    const [project, setProject] = useState([]);
    useEffect(() => {
      axios
        .get("http://localhost:3500/api/project/getproject")
        .then((res) => {
          setProject(res.data.data);
          console.log(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);
  return (
    <div
      id="work"
      className="flex flex-col items-center justify-center gap-10 md:gap-12 my-12 mx-10 md:my-20 md:mx-32 mywork"
    >
      <div className="relative flex flex-col items-center justify-center gap-1 title">
        <h1 className="p-0 md:px-8 text-5xl md:text-7xl  font-bold">
          My Works
        </h1>
        <hr />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-10 works-container">
        <a href={"https://waratechnology.netlify.app/"}>
          <img src={wt} alt="" />
        </a>
        <a href={"https://yegnarestaurant.netlify.app/"}>
          <img src={respc} alt="" />
        </a>

        <a href={"https://akptc.et/"}>
          <img src={akptc} alt="" />
        </a>

        <a href={"https://onyx-basis-395607.web.app"}>
          <img src={netflix} alt="" />
        </a>

        <a href={""}>
          <img src={td} alt="" />
        </a>
      </div>
    </div>
  );
}

export default MyWork
