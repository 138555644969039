import React from 'react'
import "./About.css"
import profile from "./../../assets/boss.JPG"
const About = () => {
  return (
    <div
      id="about"
      className="flex flex-col items-start md:items-center justify-center gap-12 my-12 mx-10 md:my-20 md:mx-32 about"
    >
      <div className="relative flex flex-col items-center justify-center gap-1  title">
        <h1 className="p-0 md:px-8 text-5xl md:text-7xl  font-bold">
          About Me
        </h1>
        <hr />
      </div>
      <div className="flex flex-col md:flex-row gap-16 about-section">
        <div className="hidden md:block md:left">
          <img
            src={profile}
            alt=""
            className="w-[130px] md:w-[32vw] md:h-[70vh]"
          />
        </div>
        <div className="flex flex-col gap-12 right">
          <div className="flex flex-col text-base md:text-lg lg:text-xl gap-6 font-light para ">
            <p>
              I'm Fullstack Developer with over 3 years of professional
              expertise in this field. My primary focus is crafting visually
              appealing, responsive, and user-friendly web applications.
            </p>
            <p>
              My passion in fullstack development nor only reflect in my
              extensive exprience but also in the enthusiasm i bring to each
              project.
            </p>
          </div>
          <div className="flex flex-col gap-3 skills">
            <div className="flex items-center gap-5 md:gap-8 lg:gap-10 transition-[0.3s] skill">
              <p className="text-lg min-w-36 md:text-xl"> HTM & CSS </p>
              <hr style={{ width: "100%" }} />
            </div>
            <div className="flex items-center gap-5 md:gap-8 lg:gap-10 transition-[0.3s] skill">
              <p className="text-lg min-w-36 md:text-xl"> Javascript </p>{" "}
              <hr style={{ width: "100%" }} />
            </div>
            <div className="flex items-center gap-5 md:gap-8 lg:gap-10 transition-[0.3s] skill">
              <p className="text-lg min-w-36 md:text-xl"> React </p>{" "}
              <hr style={{ width: "100%" }} />
            </div>
            <div className="flex items-center gap-5 md:gap-8 lg:gap-10 transition-[0.3s] skill">
              <p className="text-lg min-w-36 md:text-xl">NodeJs </p>{" "}
              <hr style={{ width: "100%" }} />
            </div>
            <div className="flex items-center gap-5 md:gap-8 lg:gap-10 transition-[0.3s] skill">
              <p className="text-lg min-w-36 md:text-xl"> MySQL </p>{" "}
              <hr style={{ width: "100%" }} />
            </div>
            <div className="flex items-center gap-5 md:gap-8 lg:gap-10 transition-[0.3s] skill">
              <p className="text-lg min-w-36 md:text-xl">MongoDB </p>{" "}
              <hr style={{ width: "100%" }} />
            </div>
            <div className="flex items-center gap-5 md:gap-8 lg:gap-10 transition-[0.3s] skill">
              <p className="text-lg min-w-36 md:text-xl">MySQL </p>{" "}
              <hr style={{ width: "100%" }} />
            </div>
            <div className="flex items-center gap-5 md:gap-8 lg:gap-10 transition-[0.3s]  skill">
              <p className="text-lg min-w-36 md:text-xl"> TailwindCSS </p>{" "}
              <hr style={{ width: "100%" }} />
            </div>
            <div className="flex items-center gap-5 md:gap-8 lg:gap-10 transition-[0.3s] skill">
              <p className="text-lg min-w-36 md:text-xl">Boootstrap </p>{" "}
              <hr style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between md:justify-around mb-16 w-full items-center achievements">
        <div className="flex flex-col items-center gap-[10px] md:gap-5 transition-[0.5s] achievement">
          <h1>3+</h1>
          <p>YEARS OF EXPRIENCE</p>
        </div>
        <div className="flex flex-col items-center gap-[10px] md:gap-5 transition-[0.5s] achievement">
          <h1>5+</h1>
          <p>PROJECT COMPLETE</p>
        </div>
        <div className="flex flex-col items-center gap-[10px] md:gap-5 transition-[0.5s] achievement">
          <h1>10+</h1>
          <p>HAPPY CLIENTS</p>
        </div>
      </div>
    </div>
  );
}

export default About
