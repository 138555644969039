import React from 'react'
import "./Hero.css"
import profile from "./../../assets/boss.JPG"
const Hero = () => {
  const handleDownload = () => {
    // Specify the path to your CV file (replace 'your-cv-file.pdf' with the actual file path)
    const cvFilePath = "./../../cv/Firaol _resume.docx";

    // Create a temporary <a> element to trigger the download
    const a = document.createElement("a");
    a.href = cvFilePath;
    a.download = "Firaol _resume.docx"; // Set the file name for download
    a.click();
  };


  return (
    <div id="home" className="flex items-center flex-col gap-6 md:gap-10  hero">
      <img
        className="mb-4 md:mb-0 h-40 w-40 md:h-72 md:w-72 lg:h-80 lg:w-80 rounded-full object-cover object-center"
        src={profile}
        alt="nature image"
      />
      <h1 className="text-center text-4xl md:text-6xl lg:text-7xl w-[90%] md:w-[80%]">
        <span>I'm Firaol </span> ,Fullstack Developer
      </h1>
      <p className="text-center leading-7 md:leading-9 lg:leading-10 w-[60%] md:w-[50%]">
        I,m Passionate Fullstack Developer, with over 3 years of exprience.
      </p>
      <div className="mb-12 md:mb-0 flex items-center gap-4 md:gap-10 justify-between hero-action">
        <div className="rounded-2xl px-6 py-1 md:px-8 md:py-2 text-xl hero-connect">
          {" "}
          Let,s talk
        </div>
        <div className="text-white rounded-2xl px-6 py-1 md:px-8 md:py-2 text-xl hero-resume" onClick={handleDownload}>
          Dowload CV
        </div>
      </div>
    </div>
  );
}

export default Hero
