import React from 'react'
import "./Footer.css"
import Email from "./../../assets/mail.png";
import Phone from "./../../assets/phone-call.png";
const Footer = () => {
    const currentYear = new Date().getFullYear();
  return (
    <div className="flex flex-col gap-6 mx-10 md:my-20 md:mx-32 footer">
      <div className="flex flex-col md:flex-row gap-12 justify-between footer-top">
        <div className="left font-medium">
          <h3>Firaol.</h3>
          <p>
            I'm a Passionate fullstack developer with over 3 years of
            proffessional expertise.
          </p>
        </div>
        <div className="flex flex-col md:flex-row right items-center gap-8">
          <div className="flex items-center gap-5 contact-detail">
            <img src={Phone} className="w-5" alt="" /> <p>+251966438359</p>
          </div>
          <div className="flex gap-5 contact-detail">
            <img src={Email} className="w-5" alt="" />{" "}
            <p>firaolteg46@gmail.com</p>
          </div>
        </div>
      </div>
      <hr />
      <div className="flex flex-col-reverse md:flex-row justify-between text-xl footer-bottom">
        <p className="buttom-left">&copy; {currentYear} Firaol Tegene</p>
        <div className="flex mb-12 gap-12 bottom-right">
          <p>Term of Services</p>
          <p>Privacy Policy</p>
          <p>Connect with me</p>
        </div>
      </div>
    </div>
  );
}

export default Footer
