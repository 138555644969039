import React, { useState } from 'react'
import { ToastContainer, toast } from "react-toast";
import "./Contact.css"
import Email from "./../../assets/mail.png"
import Phone from "./../../assets/phone-call.png"
const Contact = () => {
  
  const [result, setResult] = useState("");
  const wave = () => toast(result);
   const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "c63601ce-af3e-488b-b4ac-144ebeb5f8b0");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Submitted Successfully");
      wave()
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };
  return (
    <div
      id="contact"
      className="flex flex-col items-center justify-start md:justify-center gap-12 my-20 mx-10 md:mx-32 contact"
    >
      <div className="relative flex flex-col items-center justify-center gap-1 title">
        <h1 className="p-0 md:px-8 text-5xl md:text-7xl  font-bold">
          Get in touch
        </h1>
        <hr />
      </div>
      <div className="flex flex-col md:flex-row gap-36 container">
        <div className="flex flex-col gap-5 left">
          <h1 className="text-5xl md:text-7xl font-bold">Let,s Talk</h1>
          <p className="leading-8 text-lg md:text-xl my-5 text-[#d8d8d8]">
            I'm currently available to take on new project.please feel free to
            send me a message for any project you want me to work on.{" "}
          </p>

          <div className="flex flex-col  text-xl gap-5 text-[#d8d8d8] contact-details">
            <div className="flex items-center gap-4 md:gap-5 contact-detail">
              <img
                src={Phone}
                className="text-lg md:text-xl w-5 md:w-6"
                alt=""
              />{" "}
              <p>+251966438359</p>
            </div>
            <div className="flex gap-4 md:gap-5 contact-detail">
              <img src={Email} className="w-5 md:w-6" alt="" />{" "}
              <p>firaolteg46@gmail.com</p>
            </div>
            <div className="flex gap-4 md:gap-5 contact-detail">
              <img src={Email} className="w-5 md:w-6" alt="" />{" "}
              <p>Addis Ababa, Ethiopia</p>
            </div>
          </div>
        </div>
        <ToastContainer />

        <form
          action=""
          onSubmit={onSubmit}
          className="flex items-start flex-col gap-4 right"
        >
          <label
            htmlFor=""
            className="text-lg md:text-xl font-medium text-[#d8d8d8]"
          >
            Your name
          </label>
          <input
            type="text"
            name="name"
            placeholder="name"
            className="border-none w-[500px] h-[40px] pl-5 rounded-sm bg-[#32323c] text-[#a0a0a0] font-outfit text-lg md:text-xl"
          />
          <label htmlFor="" className="text-lg md:text-xl ">
            Your email
          </label>
          <input
            type="email"
            name="email"
            placeholder="enter your email"
            className="border-none w-[500px] h-[40px] pl-5 rounded-sm bg-[#32323c] text-[#a0a0a0] font-outfit text-lg md:text-xl"
          />
          <label htmlFor="" className="text-lg md:text-xl ">
            write you message here
          </label>
          <textarea
            name="message"
            id=""
            rows="8"
            placeholder="write your message here"
            className="w-[500px] h-[160px] border-none p-6 rounded-sm bg-[#32323c] text-[#a0a0a0] font-outfit text-lg md:text-xl"
          ></textarea>
          <button type="submit" className="sumbutton">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default Contact
